import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["container"]
	
	connect() {
		// Remove close button from the first bill item. We always want there to be
		// at least one item.
		//
		// It is important to keep the element there and only hide it. Adding more
		// bill items works by cloning the dom element of the first one and updating
		// attributes of the duplicate. For that, the close button needs to be available.
		let firstItem = this.containerTarget.querySelector(`[data-target='fields']`)
		firstItem.querySelectorAll("button.close")[0].style.display = "none"
	}
	
	addFieldset() {
		// Start by duplicating a fieldset. This is the easy way, because multiple 
		// nested form fields are identical except for a couple of details which we'll
		// take care of soon.
		let duplicate = this.containerTarget.querySelector(`[data-target='fields']`).cloneNode(true)
		
		// Take a note how many lines we already have. Since the array is 0-indexed, this
		// number tells directly the index we want to use for the next field set.
		let count = this.containerTarget.querySelectorAll(`[data-target='fields']`).length
		
		// Next, update some attributes. Rails has certain naming conventions for attributes
		// in nested fields, so we can easily follow them.
		// Field that need to be updated:
		// - name
		// - id
		// - all input values
		let inputFields = duplicate.getElementsByTagName("input")
		for (let i = 0; i < inputFields.length; i++) {
			let inputField = inputFields[i]
			inputField.value = ""
			
			let nameAttribute = inputField.getAttribute("name").replace("[0]", `[${count.toString()}]`)
			inputField.setAttribute("name", nameAttribute)
			
			let idAttribute = inputField.getAttribute("id").replace("_0_", `_${count.toString()}_`)
			inputField.setAttribute("id", idAttribute)
		}
		
		// Set hidden close button back to visible. Remember, this was hidden for the first
		// field set.
		duplicate.querySelectorAll("button.close")[0].style.display = "block"
		
		// Finally, append the duplicated and updated fieldset in the container element.
		this.containerTarget.appendChild(duplicate)
	}
}
