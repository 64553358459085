import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [
		"affair",
		"color",
		"name",
		"hourlyRate"
	]
	
	inherit() {
		let affairSelected = this.affairTarget.value !== ""
		
		if (affairSelected) {
			this.inheritColor()
			this.inheritHourlyRate()
		}
	}
	
	inheritColor() {
		let affairs = this.affairTarget
		let color = affairs.options[affairs.selectedIndex].dataset.color
		this.colorTarget.value = color
	}
	
	
	inheritHourlyRate() {
		let affairs = this.affairTarget
		let hourlyRate = affairs.options[affairs.selectedIndex].dataset.hourly_rate
		this.hourlyRateTarget.value = hourlyRate
	}
}
