import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["total", "rows"]
	
	connect() {
		// Add event listener for bill rows that are initially in the document
		this.element.querySelectorAll("input").forEach(item => {
			item.addEventListener("change", () => {
				this.updateTotal()
			})
		})
		
		// Observe changes to bill item list; add event listeners for new items, and
		// update bill total when rows are either added or removed.
		const billRowObserver = new MutationObserver( (mutationList, observer) => {
			mutationList.forEach(mutation => {
				if (mutation.type === "childList") {
					mutation.addedNodes.forEach(node => {
						try {
							if (node.dataset.target == "fields") {
								let fieldset = mutation.target
								fieldset.querySelectorAll("input").forEach(item => {
									item.addEventListener("change", () => {
										this.updateTotal()
									})
								})
							}
						}
						catch(error) {}
					})
					
					mutation.removedNodes.forEach(node => {
						try {
							if (node.dataset.target == "fields") {
								this.updateTotal()
							}
						}
						catch(error) {
						}
					})
				}
			})
		})
		const observerConfig = { childList: true }
		billRowObserver.observe(this.rowsTarget, observerConfig)
		
		this.updateTotal()
	}

	updateTotal() {
		try {
			let total = 0
			
			this.rowsTarget.querySelectorAll(".row").forEach(row => {
				let price = parseFloat(row.querySelector(".unit_price").value) || 0
				let count = parseFloat(row.querySelector(".unit_count").value) || 0
				let subtotal = price * count
				let tax_rate = parseFloat(row.querySelector(".tax_rate").value) || 0
				let tax = tax_rate * subtotal * 0.01
				total += (subtotal + tax)
			})
		
			this.totalTarget.innerHTML = total.toFixed(2)
		}
		catch (error) {
		}
	}
}
