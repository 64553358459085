import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "former", "latter" ]

	check_former() {
		if (Date.parse(this.formerTarget.value) > Date.parse(this.latterTarget.value)) {
			flatpickr(this.latterTarget, {altInput: true, altFormat: "F j, Y"}).setDate(this.formerTarget.value)
		}
	}

	check_latter() {
		if (Date.parse(this.latterTarget.value) < Date.parse(this.formerTarget.value)) {
			flatpickr(this.formerTarget, {altInput: true, altFormat: "F j, Y"}).setDate(this.latterTarget.value)
		}
	}
}
