import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		flatpickr(this.element, {
			enableTime: false,
			dateFormat: "Y-m-d",
			altInput: true,
			altFormat: "F j, Y",
			weekNumbers: true,
			locale: {
				firstDayOfWeek: 1
			}
		})
	}
}
