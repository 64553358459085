import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["canvas"]
	
	connect() {
		let ctx = this.canvasTarget.getContext("2d")
		
		let keys = this.canvasTarget.dataset.keys.split(",")
		let values = this.canvasTarget.dataset.values.split(",").map(value => parseFloat(value))
		
		let chart = new Chart(ctx, {
			type: "bar",
			data: {
				labels: keys,
				datasets: [{
					label: "Revenue",
					data: values,
					backgroundColor: 'rgba(232, 228, 25, 0.7)',
					borderColor:  'rgba(230, 227, 50, 0.5)',
					borderWidth: 1
				}]
			},
			options: {
				legend: {display: false},
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						}
					}]
				}
			}
		});
	}
}
