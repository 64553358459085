import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "link", "list" ]

	connect() {
		const observer = new IntersectionObserver( (entries, observer) => {
			entries.forEach((entry) => {
				this.handleIntersect(entry)
			})
		})
		
		observer.observe(this.listTarget)
	}
	
	handleIntersect(entry) {
		if (entry.isIntersecting) {
			this.linkTarget.style.display = "none"
		}
		else {
			this.linkTarget.style.display = "block"
		}
	}
}
