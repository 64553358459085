import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["project", "popup", "symbol"]
	
	showTooltip() {
		setTimeout(() => {
			this.symbolTarget.style.color = this.projectTarget.style.color
			this.popupTarget.innerHTML = this.projectTarget.dataset.project
			this.popupTarget.parentElement.style.display = "block"
		}, 350)
	}
	
	hideTooltip() {
		setTimeout(() => {
			this.popupTarget.parentElement.style.display = "none"
		}, 350)
	}
}
