import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["time", "duration"]

	connect() {
		let initial_hours = (parseInt(this.durationTarget.value / 60)).toString().padStart(2, "0")
		let initial_minutes = (this.durationTarget.value % 60).toString().padStart(2, "0")
		let initial_value = initial_hours + ":" + initial_minutes
		
		flatpickr(this.timeTarget, {
			enableTime: true,
			noCalendar: true,
			dateFormat: "H:i",
			time_24hr: true,
			defaultDate: initial_value,
			onChange: (selectedDates, dateStr, instance) => {
				let hours = parseInt(dateStr.split(":")[0])
				let mins  = parseInt(dateStr.split(":")[1])
				let duration =  hours * 60 + mins
				this.durationTarget.value = duration
			}
		})
	}
}
