import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["button"]
	
	connect() {
		let parent = this.element
		let children = parent.getElementsByClassName("todo_list complete")
		
		let button_text_elements = this.buttonTarget.innerHTML.split(" ")
		button_text_elements[1] = children.length
		
		let last = button_text_elements.length-1
		
		if (children.length === 1) {
			button_text_elements[last] = "list"
		}
		else {
			button_text_elements[last] = "lists"
		}
		
		this.buttonTarget.innerHTML = button_text_elements.join(" ")
	}
	
	toggle_completed() {
		let parent = this.element
		let children = parent.getElementsByClassName("todo_list complete")
		
		let button_text_elements = this.buttonTarget.innerHTML.split(" ")
		
		for (var i = 0; i < children.length; i++) {
			let child = children[i]
			
			if (child.style.display === "block") {
				child.style.display = "none"
				button_text_elements[0] = "Show"
			}
			else {
				child.style.display = "block"
				button_text_elements[0] = "Hide"
			}
		}
		
		this.buttonTarget.innerHTML = button_text_elements.join(" ")
	}
}
