import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["form", "content"]

	on_ajax_success() {
		this.contentTarget.value = ""
		this.contentTarget.focus()
	}
}
