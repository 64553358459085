import { Controller } from "stimulus"

export default class extends Controller {
	dragstart(event) {
		const dragged_item = event.target.closest("li")
		event.dataTransfer.setData("application/drag-key", dragged_item.getAttribute("data-item-id"))
		event.dataTransfer.effectAllowed = "move"
	}
	
	dragover(event) {
		event.preventDefault()
		return true
	}

	dragenter(event) {
		event.preventDefault()
	}
	
	drop(event) {
		let data = event.dataTransfer.getData("application/drag-key")
		const drop_target = event.target.closest("li")
		const dragged_item = this.element.querySelector(`[data-item-id='${data}']`).closest("li");
		const position_comparison = drop_target.compareDocumentPosition(dragged_item)
		
		// See https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
		const position_preceding = 2
		const position_following = 4
		
		if (position_comparison) {
			if (position_comparison & position_following) {
				drop_target.insertAdjacentElement('beforebegin', dragged_item);
			}
			else if (position_comparison & position_preceding) {
				drop_target.insertAdjacentElement('afterend', dragged_item);
			}
		}
		
		event.preventDefault()
	}
	
	dragend(event) {
		let item_list = event.target.closest("ul")
		let items = item_list.getElementsByTagName("li")
		let order = []
		
		for (var i = 0; i < items.length; ++i) {
			let item_id = items[i].getAttribute("data-item-id")
			order.push(item_id)
		}
		
		let data_update_url = item_list.getAttribute("data-order-update-url")
		
		Rails.ajax({
			url: data_update_url,
			type: "POST",
			data: "order=" + order.join(","),
			success: function(data) {
			}
		})
	}
}
