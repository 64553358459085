import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["last4", "brand", "expMonth", "expYear", "stripeId"]
	
	connect() {
		let pk = this.element.getAttribute("data-stripe-pk")
		var stripe = Stripe(pk);
		var elements = stripe.elements();
		
		var style = {
			base: {
				color: "#32325d",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#aab7c4"
				}
			},
			invalid: {
				color: "#d01e1e",
				iconColor: "#d01e1e"
			}
		};

		var card = elements.create("card", { style: style });
		card.mount("#card-element");
		
		// Event listener for displaying card errors
		card.addEventListener('change', ({error}) => {
			const displayError = document.getElementById('card-errors');
			if (error) {
				displayError.textContent = error.message;
			}
			else {
				displayError.textContent = '';
			}
		});
		
		let email = this.element.getAttribute("data-user-email")
		let cardForm = this
		
		this.element.querySelector('#stripe-submit').addEventListener('click', ({error}) => {
			// Wait for payment to be processed by Stripe - this will be initiated when the user
			// clicks on the "Add card" button.
			stripe.createPaymentMethod('card', card, {
				billing_details: {
					email: email,
				},
			}).then(function(result) {
				if (result.paymentMethod) {
					cardForm.stripeIdTarget.value = result.paymentMethod.id
					
					if (result.paymentMethod.card) {
						cardForm.last4Target.value    = result.paymentMethod.card.last4
						cardForm.brandTarget.value    = result.paymentMethod.card.brand
						cardForm.expMonthTarget.value = result.paymentMethod.card.exp_month
						cardForm.expYearTarget.value  = result.paymentMethod.card.exp_year
					}
					
					document.getElementById("new_card").submit()
				}
				else if (result.error) {
				}
			});
		});
	}
}
